<template>
	<div class="hero">
		<div class="hero__part hero__part--1">
			<div class="wrapper">
				<div class="hero__logo logo">
					<img
						width="70"
						height="63"
						src="@/assets/logo.png"
						srcset="@/assets/logo@2x.png 2x, @/assets/logo@3x.png 3x"
						alt="logo"
					/>
					<span>InLoveyou</span>
					<span>Get closer to each other</span>
				</div>

				<div class="row align-items-end justify-content-between">
					<div class="col-12 col-lg -7">
						<h1 class="hero__title">Looking for a <span>Couple?</span></h1>
						<p class="hero__subtitle">We are ready to help you</p>
					</div>

					<div class="col-12 col-lg-5 col-xl-4 d-none d-lg-block">
						<form-signup class="hero__form" />
					</div>
				</div>
			</div>
		</div>

		<div class="hero__part hero__part--2 d-lg-none">
			<div class="wrapper">
				<form-signup class="hero__form" />
			</div>
		</div>
	</div>
</template>

<script>
import FormSignup from '@/components/FormSignup.vue';

export default {
	name: 'hero',

	components: {
		'form-signup': FormSignup
	}
}
</script>

<style lang="scss">
.hero
{
	position: relative;

	.wrapper
	{
		position: relative;
	}

	.row
	{
		// height: 100%;
	}

	&__part
	{
		&--1
		{
			display: flex;
			align-items: stretch;
			min-height: 80vh;
			padding-bottom: 150px;
			background: url('~@/assets/hero-bg.png') no-repeat center bottom;
			background-size: cover;
		}

		&--2
		{
			.hero__form
			{
				margin-top: -100px;
				margin-bottom: 50px;
				background-color: rgba(#FFF, 0.5);
				backdrop-filter: blur(20px);
			}
		}
	}

	&__logo
	{
		position: absolute;
		top: 40px;
		left: 15px;
		padding-left: 92px;
		z-index: 1;

		img
		{
			float: left;
			margin-left: -92px;
		}

		span
		{
			display: block;

			&:first-of-type
			{
				font-size: 2.8rem;
				font-weight: 700;
				text-transform: uppercase;
				color: #417BEC;
			}

			&:last-of-type
			{
				font-size: 1.4rem;
				color: #FFF;
			}
		}
	}

	&__title,
	&__subtitle
	{
		font-weight: 700;
		color: #FFF;
		text-shadow: 0px 18px 28px rgba(9, 30, 66, 0.15);
	}

	&__title
	{
		padding-top: 15%;
		border-top: 100px solid transparent;
		text-transform: uppercase;

		span
		{
			color: #2A5FBF;
		}
	}

	&__subtitle
	{
		font-size: 3.6rem;
		text-transform: capitalize;
	}

	&__form
	{
		box-shadow: 0px 18px 28px rgba(9, 30, 66, 0.15);
	}

	@media (min-width: 768px)
	{
		&__part
		{
			&--2
			{
				.hero__form
				{
					margin-top: -150px;
				}
			}
		}

		&__form
		{
			.form__inner
			{
				
			}
		}
	}

	@media (min-width: 992px)
	{
		&__part
		{
			&--1
			{
				min-height: 100vh;
			}

			&--2
			{
				
			}
		}

		&__form
		{
			border-top-left-radius: 0;
			border-top-right-radius: 0;

			.form__inner
			{
				max-width: 370px;
			}
		}
	}

	@media (min-width: 1200px)
	{
		
	}
}
</style>

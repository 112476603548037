 <template>
	<div id="app">
		<c-hero @showModal="onToggleModal" />

		<section class="section section--no-pt">
			<div class="wrapper">
				<div class="section-heading">
					<h2 class="section-heading__title h1">Welcome</h2>
				</div>

				<div class="features">
					<div class="features__inner">
						<div class="row">
							<div v-for="(oFeature, index) in aFeatures" :key="'f' + index"
								class="col-12 col-md-4"
							>
								<div class="features__item">
									<i>
										<img
											:src="oFeature.img"
											:alt="oFeature.title"
										/>
									</i>
									<div class="features__title">{{ oFeature.title }}</div>
									<p>{{ oFeature.content }}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>

		<section class="section section--no-pt section--profile">
			<span class="decor decor--1"></span>
			<span class="decor decor--2"></span>

			<div class="wrapper">
				<div class="section-heading">
					<h2 class="section-heading__title h1">Profiles</h2>
				</div>

				<div class="profile">
					<div class="profile__filter">
						<div class="profile__filter__inner">
							<span @click="onToggleModal">All</span>
							<span @click="onToggleModal" class="active">Female</span>
							<span @click="onToggleModal">Male</span>
						</div>
					</div>

					<div class="profile__inner">
						<div class="row">
							<div v-for="oProfile in aProfiles" :key="oProfile.id"
								class="col-6 col-md-4 col-xl-3"
							>
								<div class="profile__item" @click="onToggleModal">
									<figure class="profile__img">
										<img :src="applyImgSrc(oProfile.photo)" :alt="oProfile.name + ' ' + oProfile.age" />
									</figure>
									<div class="profile__info">
										<div class="profile__name" v-text="oProfile.name + ' ' + oProfile.age"></div>
										<div class="profile__pref">
											<i v-for="i in 3" :key="'i' + i" :class="'i-' + i"></i>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>

		<section class="section section--post">
			<div class="wrapper">
				<div class="row justify-content-lg-end">
					<div class="col-12 col-lg-6">
						<div class="section-heading">
							<h2 class="section-heading__title h1">Latest stories</h2>
						</div>

						<div class="post">
							<div class="post__title">Finally, words say a lot during a first date in real life.</div>

							<p>
								I fell in love with him when I was eighteen years old. She was stupid, but for some reason very courageous - that's why, probably, she did not hesitate to immediately notify him of all her feelings. He did not reciprocate, but he did not let go anywhere either...
							</p>

							<p>
								<button class="custom-btn">Stories</button>
							</p>
						</div>
					</div>
				</div>
			</div>

			<div class="image-place">
				<img class="image-place__bg img-fluid" src="./assets/latest-stories.png" alt="latest stories" />
				<img
					class="image-place__decor"
					src="./assets/heart.png"
					srcset="@/assets/heart@2x.png 2x, @/assets/heart@3x.png 3x"
					width="321"
					height="314"
					alt="heart"
				/>
			</div>
		</section>

		<section class="section section--no-pb">
			<div class="wrapper">
				<div class="section-heading">
					<h2 class="section-heading__title h1">Last added</h2>
				</div>
			</div>

			<div class="profile profile--slider">
				<div class="profile__inner">
					<div class="profile__slider" ref="glide">
						<div data-glide-el="track" class="glide__track">
							<div class="glide__slides">
								<div
									v-for="(oProfile, index) in aSlides"
									:key="'glide__slide' + index"
									class="glide__slide"
								>
									<div class="profile__item">
										<figure class="profile__img">
											<img :src="applyImgSrc(oProfile.photo)" :alt="oProfile.name + ' ' + oProfile.age" />
										</figure>
										<div class="profile__info">
											<div class="profile__name" v-text="oProfile.name + ' ' + oProfile.age"></div>
											<div class="profile__pref">
												<i v-for="i in 3" :key="'i' + i" :class="'i-' + i"></i>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div v-if="0" class="glide__bullets" data-glide-el="controls[nav]">
							<button type="button" class="glide__bullet"
								v-for="index in aSlides.length"
								:key="'glide__bullet' + index"
								:data-glide-dir="'=' + (index - 1)"
							></button>
						</div>

						<div class="glide__controls" data-glide-el="controls">
							<div class="glide__progress">
								<i ref="progress"></i>
							</div>
							<span>{{ iCurrSlide }} / {{ aSlides.length }}</span>
							<button class="glide__prev" data-glide-dir="<"></button>
							<button class="glide__next" data-glide-dir=">"></button>
						</div>
					</div>
				</div>
			</div>
		</section>

		<footer class="footer">
			<div class="footer__line footer__line--1">
				<div class="wrapper">
					<form-signup type="long" />
				</div>
			</div>

			<div class="footer__line footer__line--2">
				<div class="wrapper">
					<div class="row align-items-center justify-content-between">
						<div class="col-12 col-md-auto order-md-last">
							<img class="footer__logos img-fluid" src="./assets/footer-logos.png" width="386" height="35" alt="logos" />
						</div>
						
						<div class="col-12 col-md-auto">
							<p>LoveChance.com {{ (new Date()).getFullYear() }} &copy; All rights reserved</p>
						</div>
					</div>
				</div>
			</div>
		</footer>

		<transition name="fade">
			<div v-show="!bHideModal" class="modal js-scrollbar-offset">
				<div class="modal__inner">
					<div class="container">
						<form-signup />
					</div>
				</div>

				<button class="modal__close" @click="onToggleModal" type="button"></button>
			</div>
		</transition>
	</div>
</template>

<script>
import axios from 'axios';

import scrolling from '@/utils/scrolling';
import Glide     from '@glidejs/glide';

import Hero       from '@/components/Hero.vue';
import FormSignup from '@/components/FormSignup.vue';

export default {
	name: 'App',

	components: {
		'c-hero': Hero,
		'form-signup': FormSignup
	},

	data: function ()
	{
		return {
			bHideModal: true,
			iCurrSlide: 1,

			aProfiles: [],
			aSlides: [],
			aFeatures: Object.freeze([
				{
					img: require('@/assets/feature/1.svg'),
					title: 'Add profile',
					content: 'The most simple registration allows you to quickly verify in the system and start communicating....'
				},
				{
					img: require('@/assets/feature/2.svg'),
					title: 'Profiles',
					content: 'More than 5000 thousand beautiful Ukrainian women, ready for real meetings, video chat and calls. All girls are verified.'
				},
				{
					img: require('@/assets/feature/3.svg'),
					title: 'Love stories',
					content: 'We have collected over 5000 thousand positive reviews from our clients about great dates...'
				}
			])
		}
	},
	created: async function ()
	{
		var aUsers = await this.fetchUsers();

		this.aProfiles = aUsers.slice(0, 8);
		this.aSlides = aUsers.slice(8, 24);

		aUsers = null;

		this.$nextTick(this.init);

		await this.sendPidAndSid();
	},

	mounted: function ()
	{
		
	},

	beforeDestroy: function ()
	{
		this.destroy();
	},

	methods: {
		onToggleModal: function (_value)
		{
			if (this.bHideModal)
			{
				scrolling.hide();
			}
			else
			{
				scrolling.restore();
			}

			this.bHideModal = !this.bHideModal;
		},

		fetchUsers: async function ()
		{
			var options = {
					method: 'post',
					url: '/api/?action=get_profiles'
				};

			try
			{
				var oResp = await axios(options);

				return Object.values(oResp.data);
			}
			catch (e)
			{
				console.error(e);

				return e;
			}
		},

		sendPidAndSid: async function ()
		{
			if (process.env.NODE_ENV === 'development') return;


			var oSearchParams = new URL(document.location).searchParams,
				sData = 'pid=' + oSearchParams.get('pid') + '&sid=' + oSearchParams.get('sid') + '&referer=' + document.referrer,
				options = {
					method: 'post',
					url: '/api/?action=send_partner_info',
					data: sData
				};

			try
			{
				await axios(options);
			}
			catch (e)
			{
				console.error(e);

				return e;
			}

			console.log("Success send pid/sid");
		},

		applyImgSrc: function (_sUrl)
		{
			return '//storage.victoriyaclub.com' + _sUrl;
		},

		init: function ()
		{
			if (!this.aSlides.length) return;


			var that = this,
				option = {
					type: 'carousel',
					perView: 4,
					gap: 30,
					keyboard: false,
					swipeThreshold: 10,
					dragThreshold: 30,
					autoplay: 5000,
					animationDuration: 500,
					animationTimingFunc: 'cubic-bezier(0,0,0.2,1)',
					breakpoints: {
						1199: {
							perView: 3
						},
						991: {
							perView: 2.5,
							peek: {
								before: 15,
								after: 0
							}
						},
						767: {
							perView: 1.5,
							peek: {
								before: 15,
								after: 0
							}
						},
						370: {
							perView: 1,
							peek: {
								before: 15,
								after: 15
							}
						}
					}
				};

			var oSlider = this.slider = new Glide(this.$refs.glide, option);

			oSlider.on(['mount.after', 'run'], function()
			{
				var nProgress = that.$refs.progress,
					iLenght = that.aSlides.length;

				that.iCurrSlide = oSlider.index + 1;

				nProgress.style.width = (that.iCurrSlide / iLenght * 100).toFixed(2) + '%';
			});

			oSlider.mount();
		},

		destroy: function ()
		{
			if (this.slider && this.slider.destroy)
			{
				this.slider.destroy();
				this.slider = null;
			}
		}
	}
}
</script>

<style lang="scss">
@import "~@glidejs/glide/dist/css/glide.core.min.css";
@import './styles/style';

.section
{
	&--profile
	{
		overflow: hidden;

		.decor
		{
			position: absolute;
			z-index: -1;

			&::before
			{
				display: block;
				width: 100%;
				height: 100%;
				border-radius: 50%;
			}

			&--1
			{
				top: 10%;
				left: 37%;
				width: 560px;
				height: 640px;
				opacity: 0.3;

				&::before
				{
					content: '';
					background-color: #59B1E6;
					filter: blur(100px);
				}
			}
			&--2
			{
				bottom: 5%;
				left: -17%;
				width: 560px;
				height: 600px;
				opacity: 0.2;

				&::before
				{
					content: '';
					background-color: rgba(229, 10, 84, 0.5);
					filter: blur(100px);
				}
			}
		}
	}

	&--post
	{
		background: #F0F7FD;

		.image-place
		{
			position: relative;
			max-width: 80%;
			margin-top: 70px;

			&__bg
			{
				height: 100%;
				object-fit: cover;
				border-radius: 0 150px 150px 150px;
			}

			&__decor
			{
				position: absolute;
				width: 150px;
				height: auto;
				bottom: -25px;
				right: -50px;
			}
		}

		@media (min-width: 768px)
		{
			.image-place
			{
				&__decor
				{
					width: 250px;
					bottom: -65px;
					right: -115px;
				}
			}
		}

		@media (min-width: 992px)
		{
			margin-top: 100px;

			.wrapper
			{
				padding-top: 5vw;
				padding-bottom: 5vw;
			}

			.image-place
			{
				position: absolute;
				left: 0;
				top: 50px;
				bottom: 50px;
				max-width: 45%;
				margin-top: 0;

				&__decor
				{
					bottom: -75px;
					right: -115px;
				}
			}
		}

		@media (min-width: 1199px)
		{
			.image-place
			{
				top: -100px;
				bottom: 70px;
				max-width: 50%;
			}
		}
	}
}

.features
{
	&__inner
	{
		margin-bottom: -30px;
	}

	&__item
	{
		max-width: 400px;
		margin: auto;
		margin-bottom: 30px;
		line-height: 1.7;
		font-size: 1.4rem; 
		text-align: center;
		color: #414042;

		i
		{
			display: inline-block;
			margin-bottom: 15px;
			height: 90px;
			font-size: 0;

			&::before
			{
				content: '';
				display: inline-block;
				vertical-align: bottom;
				height: 100%;
			}
		}

		p
		{

		}
	}

	&__title
	{
		margin-bottom: 20px;
		line-height: 1;
		font-size: 2.4rem;
		font-weight: 700;
		color: #333;
	}

	@media (min-width: 992px)
	{
		&__item
		{
			font-size: 1.8rem;
		}

		&__title
		{
			font-size: 3.6rem;
		}
	}
}

.profile
{
	&--slider
	{
		@media (min-width: 1530px)
		{
			margin-left: calc((100% - 1530px)/ 2);
		}

		.profile__inner
		{
			margin: 0;
			overflow: hidden;
		}

		.profile__slider
		{
			max-width: 1530px;
		}

		.profile__item
		{
			box-shadow: none;
		}

		.glide__track
		{
			overflow: visible;
		}
	}

	&__filter
	{
		margin-bottom: 40px;
		line-height: 1;
		font-size: 1.4rem;
		font-weight: 700;
		text-align: center;
		color: #414042;

		&__inner
		{
			display: inline-block;
			vertical-align: top;
			padding: 11px 14px;
			background-color: #FBFBFB;
			border: 1px solid #FFF;
			box-shadow: 0px 18px 28px rgba(9, 30, 66, 0.15);
			border-radius: 34px;
		}

		span
		{
			display: inline-block;
			vertical-align: top;
			margin-left: 25px;
			padding: 0 25px;
			line-height: 32px;
			cursor: pointer;
			background-color: #F4F6FA;
			border-radius: 100px;
			@include transition(
				color 200ms ease-in-out,
				background-color 200ms ease-in-out
			);

			&:first-child
			{
				margin-left: 0;
			}

			&.active,
			&:hover
			{
				color: #FFF;
				background-color: #204687;
				box-shadow: 0px 18px 28px rgba(9, 30, 66, 0.15), inset 0px 1px 14px rgba(255, 255, 255, 0.25);
				
			}
		}

		@media (min-width: 768px)
		{
			font-size: inherit;

			span
			{
				margin-left: 30px;
				padding: 0 35px;
				line-height: 42px;
			}
		}
	}

	&__inner
	{
		margin-bottom: -30px;

		@media (min-width: 992px)
		{
			margin-bottom: -60px;
		}
	}

	&__item
	{
		position: relative;
		margin-bottom: 30px;
		line-height: 1;
		cursor: pointer;
		overflow: hidden;
		border-radius: 16px;
		box-shadow: 0px 18px 28px rgba(9, 30, 66, 0.15);

		@media (min-width: 992px)
		{
			margin-bottom: 60px;
			border-radius: 24px;
		}
	}

	&__img
	{
		position: relative;
		overflow: hidden;

		&::before
		{
			content: "";
			display: block;
			padding-top: percentage(500/360);
		}

		img
		{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&__info
	{
		position: relative;
		margin-top: -60px;
		padding: 20px 10px;
		text-align: center;
		background-color: #FFF;
		border-radius: 6px 6px 0 0;
		overflow: hidden;

		@media (min-width: 768px)
		{
			padding: 30px 15px 35px;
		}

		@media (min-width: 992px)
		{
			border-radius: 12px 12px 0 0;
		}
	}

	&__name
	{
		margin-bottom: 15px;
		line-height: 1.2;
		font-size: 1.6rem;
		font-weight: 700;
		color: #414042;

		@media (min-width: 992px)
		{
			margin-bottom: 20px;
			font-size: 2.4rem;
		}
	}

	&__pref
	{
		$size: 24px;
		$offsetX-sm: 18px;
		$offsetX-lg: 28px;

		margin-left: $offsetX-sm * -1;
		line-height: $size;
		font-size: 1.4rem;
		color: #828282;

		i
		{
			display: inline-block;
			vertical-align: top;
			width: $size;
			height: $size;
			margin-left: $offsetX-sm;
			background-position: center;
			background-repeat: no-repeat;
		}

		.i-1
		{
			background-image: url('~@/assets/preferences/1.svg');
		}
		.i-2
		{
			background-image: url('~@/assets/preferences/2.svg');
		}
		.i-3
		{
			background-image: url('~@/assets/preferences/3.svg');
		}

		@media (min-width: 992px)
		{
			margin-left: $offsetX-lg * -1;

			&::before
			{
				content: 'I need';
				display: inline-block;
				margin-left: $offsetX-lg;
			}

			i
			{
				margin-left: $offsetX-lg;
			}
		}
	}
}

.post
{
	max-width: 490px;
	margin-left: auto;
	margin-right: auto;
	line-height: 1.7;
	text-align: center;
	color: #333;

	&__title
	{
		margin-bottom: 25px;
		line-height: 1.4;
		font-size: 2.5rem;
		font-weight: 700;
		text-transform: capitalize;
		color: #204687;
	}

	.custom-btn
	{
		max-width: 365px;
		margin-top: 50px;
	}

	@media (min-width: 576px)
	{
		&__title
		{
			font-size: 3.6rem;
		}
	}
}

.footer
{
	background: radial-gradient(
		182.3% 182.3% at 61.39% -16.18%, 
		rgba(251, 253, 255, 0) 19.01%, 
		rgba(222, 238, 249, 0.36) 48.32%, 
		#F8E9F0 72.25%, 
		rgba(250, 252, 254, 0) 95.63%
		);

	&__logos
	{
		margin-bottom: 35px;
	}

	&__line--1
	{
		padding-top: 50px;
		padding-bottom: 50px;
	}

	&__line--2
	{
		padding-top: 35px;
		padding-bottom: 55px;
		line-height: 1;
		font-size: 1.4rem;
		color: #949DAD;
		text-align: center;
	}

	@media (min-width: 768px)
	{
		&__logos
		{
			margin-bottom: 0;
		}
	}

	@media (min-width: 992px)
	{
		&__line--1
		{
			padding-top: 100px;
			padding-bottom: 100px;
		}
	}
}

.glide
{
	&__controls
	{
		max-width: 1040px;
		margin: auto;
		padding: 0 15px;
		line-height: 1;
		text-align: right;

		span
		{
			float: left;
			font-size: 1.8rem;
			font-weight: 700;
			color: #414042;

			&:first-letter
			{
				font-size: 2.4rem;
			}
		}
	}

	&__prev,
	&__next
	{
		display: inline-block;
		vertical-align: top;
		width: 24px;
		height: 24px;
		border: none;
		background: none transparent;
		cursor: pointer;

		&::before
		{
			content: '';
			display: block;
			width: 14px;
			height: 14px;
			margin: auto;
			border-width: 0;
			border-color: #204687;
			border-style: solid;
		}
	}

	&__prev
	{
		&::before
		{
			border-top-width: 3px;
			border-left-width: 3px;
			transform: rotate(-45deg);
		}
	}

	&__next
	{
		margin-left: 15px;

		&::before
		{
			border-top-width: 3px;
			border-right-width: 3px;
			transform: rotate(45deg);
		}
	}

	&__progress
	{
		margin-bottom: 10px;
		background-color: #3D6DC9;

		i
		{
			display: block;
			border-top: 3px solid #34BDFC;
		}
	}
}


.fade-enter-active,
.fade-leave-active {
	transition: opacity 300ms;
}

.fade-enter,
.fade-leave-to {
	transition: opacity 300ms;
	opacity: 0;
}

.modal
{
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	height: 100%;
	background-color: rgba(#040B1C, 0.9);
	overflow: auto;
	z-index: 10;
	backface-visibility: hidden;

	&::before
	{
		content: '';
		display: inline-block;
		vertical-align: middle;
		width: 0;
		height: 100%;
	}

	&__inner
	{
		position: relative;
		display: inline-block;
		vertical-align: middle;
		min-width: 100%;
		padding: 30px 0;
	}

	&__close
	{
		position: absolute;
		top: 20px;
		right: 20px;
		padding: 30px 30px 0 0;
		background: #FFF;
		border: none;
		border-radius: 50%;
		cursor: pointer;
		box-shadow: 0px 18px 28px rgba(9, 30, 66, 0.15);

		&::before,
		&::after
		{
			content: "";
			position: absolute;
			background: #204687;
			transform: rotate(45deg);
		}

		&::before
		{
			width: 2rem;
			height: 4px;
			top: 50%;
			left: 50%;
			margin-top: -2px;
			margin-left: -1rem;
		}

		&::after
		{
			width: 4px;
			height: 2rem;
			left: 50%;
			top: 50%;
			margin-top: -1rem;
			margin-left: -2px;
		}

		@media (min-width: 768px)
		{
			padding: 55px 55px 0 0;

			&::before
			{
				width: 2.8rem;
				margin-left: -1.4rem;
			}

			&::after
			{
				height: 2.8rem;
				margin-top: -1.4rem;
			}
		}
	}

	.form-signup
	{
		max-width: 560px;
		margin-left: auto;
		margin-right: auto;
	}

	.form__inner
	{
		max-width: 370px;
	}
}
</style>
